import React from "react";
import { Flex, Indicator, Progress } from "@nef/core";
import {
  ApiResponseNames,
  FieldNames,
  SelectOptions,
  SettlementValues,
} from "components/fields/fieldConstants";
import moment from "moment";
import {
  eqrcAdvColumns,
  eqrcAlertConfigColumns,
  eqrcFatFingerColumns,
  eqrcGrossExposureColumns,
  eqrcMarketImpactColumns,
  eqrcMaxNotionalOrderColumns,
  eqrcMaxSharesPerOrderColumns,
  eqrcOrderRateColumns,
  eqrcOrderTypeColumns,
  eqrcRestrictedStockColumns,
  eqrcResultsColumns,
  eqrcSharesLocatedBrokerListColumns,
  eqrcSharesLocatedCheckColumns,
  eqrcShortSaleColumns,
} from "components/settings/eqrc/columns/columnGroups";
import {
  EQRCColumnDefinitions,
  EQRCColumnDefinitions as EqrcMonitoringColumns,
} from "components/eqrc/columnDefinitions";
import { columns2 as EqrcDetailColumnsDefinitions } from "components/eqrc/singleOrder.details";
import {
  AguRiskAlertColumns,
  AguRiskAlertCreateValidationColumns,
  AguRiskAlertEditValidationColumns,
  RiskAlertColumns,
  RiskAlertCreateValidationColumns,
  RiskAlertEditValidationColumns,
  getYesOrNo,
} from "components/settings/ptra/columns";
import { RiskAlertEmailManagerColumns } from "components/settings/ptra/email/columns";
import { CellCopy } from "components/standardTable/cellCopy";
import columnDefinitions, {
  getNotionalValue,
  PriceType,
  sortDateColumn,
  sortFormattedNumber,
} from "components/standardTable/columnDefinitions";
import { TooltipTableCell } from "components/standardTable/tooltipCell";
import { formatNumberToCurrencyString, getSideLabel } from "utils/js.utils";
import { colors } from "./colors";
import { LimoProgress } from "./components/limitMonitor/progress";
import styled from "styled-components";
import { OrderEntryColumns } from "components/orderEntry/columns";
import { KeycloakExportColumns } from "components/settings/keycloakExport/columns";
import { BREACHES, SINGLE_ORDER_PREFIX } from "components/eqrc/eqrc.types";
import { pvRejectColumns, pvRequestColumns } from "components/pvRejects/member/columns";
import {
  columns as pvSupervisorColumns,
  columns2 as pvSupervisorColumns2,
} from "components/pvRejects/supervisor/columns";
import _ from "lodash";
import { PTR_AUDIT_ACTION } from "components/settings/constant";
import { enumKeys } from "components/eqrc/utils";

const IndicatorText = styled.span`
  margin-left: 5px;
`;

const emptyTableCell = "";

export const STANDARD_TABLE_DEFAULT_COLUMN_SET = "Nasdaq Default";
export const RequestResult = Object.freeze({
  success: "Success",
  error: "Error",
});

const convertUploadStatus = status => {
  switch (status) {
    case "+":
      return "New Record";
    case "P":
      return "Processing";
    case "S":
      return "Parsed";
    case "?":
      return "Unknown";
    case "D":
      return "Done";
    case "E":
      return "Done With Errors";
    case "R":
      return "Rejected";
    case "F":
      return "Failed";
    case "Q":
      return "Queued";
    case "A":
      return "Accepted";
    default:
      return status;
  }
};

const convertUploadRecordType = recordType => {
  switch (recordType) {
    case "T":
      return "Report";
    case "Z":
      return "Reverse";
    case "A":
      return "Accept";
    case "B":
      return "Break";
    case "C":
      return "Cancel";
    case "D":
      return "Decline";
    default:
      return recordType;
  }
};

export const getAggProgressAttr = (data, type, isProforma) => {
  const isAlertActive = data.riskConfig[ApiResponseNames.isAlertActive];
  const isHoldActive = data.riskConfig[ApiResponseNames.isHoldActive];
  const isRejectActive = data.riskConfig[ApiResponseNames.isRejectActive];
  const limits = {
    total: data[`${type.charAt(0)}a`],
    alert: isAlertActive ? data.riskConfig[`${type}AlertLimit`] : 0,
    kill: data.riskConfig[`${type}RejectLimit`],
  };
  if (isProforma) {
    limits.total = data[`p${type.charAt(0)}a`];
  }
  limits.hold = isHoldActive ? data.riskConfig[`${type}HoldLimit`] : limits.alert;
  let value = 0;
  let color = "primary";
  let kind = "alert";
  let limit = limits.alert;
  let next = limits.hold;
  if (isAlertActive && limits.alert >= limits.total && limits.total < limits.hold) {
    // alert
    if (limits.alert === 0) {
      value = 0;
    } else {
      value = (limits.total / limits.alert) * 100;
    }
  } else if (
    isHoldActive &&
    ((limits.hold >= limits.total && limits.total < limits.kill) || !isRejectActive)
  ) {
    // hold
    if (limits.hold === 0) {
      value = 0;
    } else {
      value = ((limits.total - limits.alert) / (limits.hold - limits.alert)) * 100;
    }
    color = "warning";
    kind = "hold";
    limit = limits.hold;
    next = limits.kill;
  } else if (isRejectActive) {
    // kill
    if (limits.kill === 0) {
      value = 0;
    } else {
      value = ((limits.total - limits.hold) / (limits.kill - limits.hold)) * 100;
    }
    color = "danger";
    kind = "kill";
    limit = limits.kill;
    next = "";
  } else {
    value = 100;
    color = "default";
    kind = "none";
    limit = null;
  }

  value = formatPercentTo(value);

  return {
    value,
    children: color === "default" ? "Inactive" : `${value}%`,
    color,
    kind,
    limit,
    next,
  };
};

export const getHoldDefaultActionLabel = value => {
  switch (value) {
    case "A":
      return "Allow";
    case "I":
      return "Inhibit";
    default:
      return "";
  }
};

export const getPerTradeDefaultActionLabel = value => {
  switch (value) {
    case "W":
      return "Alert";
    case "H":
      return "Hold";
    default:
      return "";
  }
};

const convertBreachFlag = (flag, isExec) => {
  const valueToLabel = {
    b: "Aggregate",
    B: "Aggregate",
    s: "Per Trade",
    S: "Per Trade",
    M: "Max Trade",
  };
  const label = valueToLabel[flag];
  if (label) {
    return `${isExec ? "EP -" : "CP -"} ${valueToLabel[flag]}`;
  }
  return false;
};

const getBreachReason = row => {
  const exec = convertBreachFlag(row[ApiResponseNames.execBreachFlag], true);
  const contra = convertBreachFlag(row[ApiResponseNames.contraBreachFlag], false);
  return `${exec ? exec : ""}${contra ? `${exec ? ", " + contra : contra}` : ""}`;
};

const HKTableColumns = [
  {
    Header: "Exec MPID",
    accessor: ApiResponseNames.executingMPID,
    width: 100,
    id: "execmpid",
  },
  {
    Header: "Exec Give Up",
    accessor: ApiResponseNames.executingGiveUpMPID,
    width: 100,
    id: "execgiveup",
  },
  {
    Header: "Side",
    accessor: data => getSideLabel(data[ApiResponseNames.side]),
    width: 75,
    id: "side",
  },
  { Header: "Symbol", accessor: ApiResponseNames.symbol, width: 75, id: "symbol" },
  {
    Header: "Quantity",
    accessor: row => row[ApiResponseNames.quantity]?.toLocaleString(),
    width: 85,
    id: "quantity",
    sortType: (a, b) =>
      sortFormattedNumber(
        a.original[ApiResponseNames.quantity],
        b.original[ApiResponseNames.quantity]
      ),
    isNumeric: true,
  },
  {
    Header: "Price",
    accessor: data => data[ApiResponseNames.price],
    Cell: ({ row }) => formatNumberToCurrencyString(row.original[ApiResponseNames.price]),
    sortType: "basic",
    isNumeric: true,
    width: 85,
    id: "price",
  },
  {
    id: ApiResponseNames.priceTradeDigit,
    Header: "Price Type",
    accessor: row => PriceType[row[ApiResponseNames.priceTradeDigit]],
    width: 120,
  },
  {
    Header: "Notional",
    accessor: data => getNotionalValue(data),
    Cell: ({ row }) => formatNumberToCurrencyString(getNotionalValue(row.original)),
    sortType: "basic",
    isNumeric: true,
    width: 115,
    id: "notional",
  },
  {
    Header: "Trade Status",
    accessor: data => TradeStatusVals[data.tradeStatus]?.label,
    width: 120,
    id: "tradeStatus",
  },
  {
    Header: "Control #",
    accessor: ApiResponseNames.controlNum,
    Cell: ({ row }) => {
      if (row?.original[ApiResponseNames.controlNum]) {
        const id = `copy-${row.original[ApiResponseNames.controlNum]}`;
        return <CellCopy id={id} value={row.original[ApiResponseNames.controlNum]} />;
      } else {
        return "";
      }
    },
    width: 115,
    id: "controlnum",
  },
  {
    Header: "Trade Source",
    accessor: data => TradeSources[data[ApiResponseNames.tradeSource]],
    width: 110,
    id: "tradeSource",
  },
  {
    Header: "Contra MPID",
    accessor: ApiResponseNames.contraMPID,
    width: 120,
    id: "contrampid",
  },
  {
    Header: "Contra Give Up",
    accessor: ApiResponseNames.contraGiveUpMPID,
    width: 130,
    id: "contragiveup",
  },
  {
    Header: "Breach Reason",
    accessor: data => getBreachReason(data),
    width: 300,
    id: "breachreason",
  },
];

const UploadStatusTableColumns = table => {
  let columns = [
    {
      id: "recordtype",
      Header: "Record Type",
      accessor: row => convertUploadRecordType(row.recordType),
    },
  ];
  if (table === StandardTables.UPLOAD_ERRORS) {
    columns = columns.concat([
      {
        id: "status",
        Header: "Status",
        accessor: row => convertUploadStatus(row[ApiResponseNames.uploadStatus]),
      },
      {
        Header: "Error Message",
        accessor: row =>
          row.status === AckTypes.REJECT
            ? row[ApiResponseNames.rejectText]
            : row[ApiResponseNames.uploadMessage],
        id: ApiResponseNames.rejectText,
        Cell: ({ row }) => {
          return (
            <TooltipTableCell id={`error_message_${row.original.id}`}>
              {row.original[ApiResponseNames.uploadStatus] === AckTypes.REJECT
                ? row.original[ApiResponseNames.rejectText]
                : row.original[ApiResponseNames.uploadMessage]}
            </TooltipTableCell>
          );
        },
      },
      { id: "side", Header: "Side", accessor: row => getSideLabel(row.side) },
    ]);
  }
  columns = columns.concat(
    columnDefinitions().filter(col => ["Symbol", "Quantity", "Price"].includes(col.Header))
  );
  columns = columns.concat({
    Header: "Exec MPID",
    accessor: "executingMPID",
    id: "executingMPID",
  });
  columns = columns.concat(columnDefinitions().filter(col => col.Header === "Contra MPID"));
  columns = columns.concat({
    Header: "Control #",
    accessor: "controlNum",
    id: "controlNum",
  });
  columns = columns.concat([
    {
      Header: "Action MPID",
      accessor: row =>
        row.reportingParty === ReportingParty.Executing
          ? row[ApiResponseNames.executingMPID]
          : row[ApiResponseNames.contraMPID],
      id: "actionMPID",
    },
  ]);
  return columns;
};

const limoLimitWidth = 120;
const limoLimitWidth2 = 135;
const limoProgressWidth = 140;
export const riskConfigStr = "riskConfig";
const aggBuyStateStr = "aggBuyState";
const aggSellStateStr = "aggSellState";
const aggNetStateStr = "aggNetState";
const LIMIT_TYPE = {
  BUY: "buy",
  SELL: "sell",
  NET: "net",
};

const ptraGetColumnValueForSort = (cellValue, { hideInactive, checks, hasRiskConfigPrefix }) =>
  hideInactive && checks.find(check => check === true) ? Number.MIN_SAFE_INTEGER : cellValue;

const getPTRValueParent = (rows, hasRiskConfigPrefix, prefixStr) =>
  rows.map(row => (hasRiskConfigPrefix ? row.original[prefixStr] : row.original));

const formatPTRTotalColumn = value => {
  return value.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const PTR_COLUMNS = (hasRiskConfigPrefix, hideInactive, isRawExport, prefixStr = riskConfigStr) => {
  return {
    [ApiResponseNames.ackType]: {
      accessor: "ackType",
      Header: "Status",
      id: ApiResponseNames.ackType,
    },
    [ApiResponseNames.rejectText]: {
      accessor: row => row[ApiResponseNames.rejectText].replace(/,/g, ""),
      Header: "Error Message",
      id: ApiResponseNames.rejectText,
    },
    [ApiResponseNames.clearingFirmMPID]: {
      Header: "Clearing MPID",
      accessor: `${hasRiskConfigPrefix ? `${hasRiskConfigPrefix ? `${prefixStr}.` : ""}` : ""}${
        ApiResponseNames.clearingFirmMPID
      }`,
      width: 70,
      id: ApiResponseNames.clearingFirmMPID,
    },
    [ApiResponseNames.clearingFirmNum]: {
      accessor: "clearingFirmNum",
      Header: "Clearing Number",
      id: ApiResponseNames.clearingFirmNum,
    },
    [ApiResponseNames.correspondentMPID]: {
      Header: "Correspondent MPID",
      accessor: `${hasRiskConfigPrefix ? `${prefixStr}.` : ""}${
        ApiResponseNames.correspondentMPID
      }`,
      width: 125,
      id: ApiResponseNames.correspondentMPID,
    },
    [ApiResponseNames.useNetTrading]: {
      Header: "B/S vs N",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        return row[ApiResponseNames.useNetTrading]?.toString();
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        return data[ApiResponseNames.useNetTrading] ? "Net" : "Buy / Sell";
      },
      width: limoLimitWidth,
      id: ApiResponseNames.useNetTrading,
    },
    [ApiResponseNames.totalBuy]: {
      Header: "Total Buy (Current)",
      accessor: row => formatPTRTotalColumn(row[ApiResponseNames.totalBuy]),
      width: limoLimitWidth,
      id: ApiResponseNames.totalBuy,
      sortType: (a, b) => {
        const aa = ptraGetColumnValueForSort(a.original[ApiResponseNames.totalBuy], {
          hideInactive,
          checks: [],
        });
        const bb = ptraGetColumnValueForSort(b.original[ApiResponseNames.totalBuy], {
          hideInactive,
          checks: [],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.totalBuyProforma]: {
      Header: "Total Buy (Potential)",
      accessor: row => formatPTRTotalColumn(row[ApiResponseNames.totalBuyProforma]),
      width: limoLimitWidth,
      id: ApiResponseNames.totalBuyProforma,
      sortType: (a, b) => {
        const aa = ptraGetColumnValueForSort(a.original[ApiResponseNames.totalBuyProforma], {
          hideInactive,
          checks: [],
        });
        const bb = ptraGetColumnValueForSort(b.original[ApiResponseNames.totalBuyProforma], {
          hideInactive,
          checks: [],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.buyAlertLimit]: {
      Header: "Buy Alert Limit",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        if (isRawExport) {
          return row[ApiResponseNames.buyAlertLimit];
        } else {
          return row[ApiResponseNames.buyAlertLimit]?.toLocaleString();
        }
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        if (
          hideInactive &&
          (data[ApiResponseNames.useNetTrading] || !data[ApiResponseNames.isAlertActive])
        ) {
          return emptyTableCell;
        } else {
          return data[ApiResponseNames.buyAlertLimit]?.toLocaleString();
        }
      },
      width: limoLimitWidth2,
      id: ApiResponseNames.buyAlertLimit,
      sortType: (a, b) => {
        [a, b] = getPTRValueParent([a, b], hasRiskConfigPrefix, prefixStr);

        const aa = ptraGetColumnValueForSort(a[ApiResponseNames.buyAlertLimit], {
          hideInactive,
          checks: [a[ApiResponseNames.useNetTrading], !a[ApiResponseNames.isAlertActive]],
        });
        const bb = ptraGetColumnValueForSort(b[ApiResponseNames.buyAlertLimit], {
          hideInactive,
          checks: [b[ApiResponseNames.useNetTrading], !b[ApiResponseNames.isAlertActive]],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.buyHoldLimit]: {
      Header: "Buy Hold Limit",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        if (isRawExport) {
          return row[ApiResponseNames.buyHoldLimit];
        } else {
          return row[ApiResponseNames.buyHoldLimit]?.toLocaleString();
        }
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }

        if (
          hideInactive &&
          (data[ApiResponseNames.useNetTrading] || !data[ApiResponseNames.isHoldActive])
        ) {
          return emptyTableCell;
        } else {
          return data[ApiResponseNames.buyHoldLimit]?.toLocaleString();
        }
      },
      width: limoLimitWidth2,
      id: ApiResponseNames.buyHoldLimit,
      sortType: (a, b) => {
        [a, b] = getPTRValueParent([a, b], hasRiskConfigPrefix, prefixStr);

        const aa = ptraGetColumnValueForSort(a[ApiResponseNames.buyHoldLimit], {
          hideInactive,
          checks: [a[ApiResponseNames.useNetTrading], !a[ApiResponseNames.isHoldActive]],
        });
        const bb = ptraGetColumnValueForSort(b[ApiResponseNames.buyHoldLimit], {
          hideInactive,
          checks: [b[ApiResponseNames.useNetTrading], !b[ApiResponseNames.isHoldActive]],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.buyRejectLimit]: {
      Header: "Buy Kill Limit",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        if (isRawExport) {
          return row[ApiResponseNames.buyRejectLimit];
        } else {
          return row[ApiResponseNames.buyRejectLimit]?.toLocaleString();
        }
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        if (
          hideInactive &&
          (data[ApiResponseNames.useNetTrading] || !data[ApiResponseNames.isRejectActive])
        ) {
          return emptyTableCell;
        } else {
          return data[ApiResponseNames.buyRejectLimit]?.toLocaleString();
        }
      },
      width: limoLimitWidth,
      id: "buyKillLimit",
      sortType: (a, b) => {
        [a, b] = getPTRValueParent([a, b], hasRiskConfigPrefix, prefixStr);

        const aa = ptraGetColumnValueForSort(a[ApiResponseNames.buyRejectLimit], {
          hideInactive,
          checks: [a[ApiResponseNames.useNetTrading], !a[ApiResponseNames.isRejectActive]],
        });
        const bb = ptraGetColumnValueForSort(b[ApiResponseNames.buyRejectLimit], {
          hideInactive,
          checks: [b[ApiResponseNames.useNetTrading], !b[ApiResponseNames.isRejectActive]],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [aggBuyStateStr]: {
      Header: "Agg. Buy State",
      Cell: ({ row }) => {
        const alertActive = row.original.riskConfig[ApiResponseNames.isAlertActive];
        const holdActive = row.original.riskConfig[ApiResponseNames.isHoldActive];
        const rejectActive = row.original.riskConfig[ApiResponseNames.isRejectActive];
        if (
          row.original.riskConfig[ApiResponseNames.useNetTrading] ||
          (!alertActive && !holdActive && !rejectActive)
        ) {
          return emptyTableCell;
        } else {
          const actualPercents = calculateRiskPercent(row.original, LIMIT_TYPE.BUY, false);
          const proformaPercents = calculateRiskPercent(row.original, LIMIT_TYPE.BUY, true);
          return (
            <LimoProgress
              id={`buy${row.original.id}`}
              {...getLimoProgressPropsFromPercents(
                actualPercents,
                proformaPercents,
                alertActive,
                holdActive,
                rejectActive,
                "Buy"
              )}
              hideAlert={false}
            />
          );
        }
      },
      accessor: row => {
        const propsCurrent = getAggProgressAttr(row, LIMIT_TYPE.BUY);
        const proformaProps = getAggProgressAttr(row, LIMIT_TYPE.BUY, true);
        return `${proformaProps.value}% of ${proformaProps.kind
          .substr(0, 1)
          .toUpperCase()}${proformaProps.kind.substr(1)}; ${
          propsCurrent.value
        }% of ${propsCurrent.kind.substr(0, 1).toUpperCase()}${propsCurrent.kind.substr(1)}`;
      },
      width: limoProgressWidth,
      id: "buyprogressbarr",
      sortType: (a, b) => {
        return limoAggSort(a.original, b.original, LIMIT_TYPE.BUY);
      },
    },
    [ApiResponseNames.totalSell]: {
      Header: "Total Sell (Current)",
      accessor: row => formatPTRTotalColumn(row[ApiResponseNames.totalSell]),
      width: limoLimitWidth,
      id: ApiResponseNames.totalSell,
      sortType: (a, b) => {
        const aa = ptraGetColumnValueForSort(a.original[ApiResponseNames.totalSell], {
          hideInactive,
          checks: [],
        });
        const bb = ptraGetColumnValueForSort(b.original[ApiResponseNames.totalSell], {
          hideInactive,
          checks: [],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.totalSellProforma]: {
      Header: "Total Sell (Potential)",
      accessor: row => formatPTRTotalColumn(row[ApiResponseNames.totalSellProforma]),
      width: limoLimitWidth,
      id: ApiResponseNames.totalSellProforma,
      sortType: (a, b) => {
        const aa = ptraGetColumnValueForSort(a.original[ApiResponseNames.totalSellProforma], {
          hideInactive,
          checks: [],
        });
        const bb = ptraGetColumnValueForSort(b.original[ApiResponseNames.totalSellProforma], {
          hideInactive,
          checks: [],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.sellAlertLimit]: {
      Header: "Sell Alert Limit",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        if (isRawExport) {
          return row[ApiResponseNames.sellAlertLimit];
        } else {
          return row[ApiResponseNames.sellAlertLimit]?.toLocaleString();
        }
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        if (
          hideInactive &&
          (data[ApiResponseNames.useNetTrading] || !data[ApiResponseNames.isAlertActive])
        ) {
          return emptyTableCell;
        } else {
          return data[ApiResponseNames.sellAlertLimit]?.toLocaleString();
        }
      },
      width: limoLimitWidth2,
      id: ApiResponseNames.sellAlertLimit,
      sortType: (a, b) => {
        [a, b] = getPTRValueParent([a, b], hasRiskConfigPrefix, prefixStr);

        const aa = ptraGetColumnValueForSort(a[ApiResponseNames.sellAlertLimit], {
          hideInactive,
          checks: [a[ApiResponseNames.useNetTrading], !a[ApiResponseNames.isAlertActive]],
        });
        const bb = ptraGetColumnValueForSort(b[ApiResponseNames.sellAlertLimit], {
          hideInactive,
          checks: [b[ApiResponseNames.useNetTrading], !b[ApiResponseNames.isAlertActive]],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.sellHoldLimit]: {
      Header: "Sell Hold Limit",
      accessor: row => {
        let data = row;
        if (hasRiskConfigPrefix) {
          data = row[prefixStr];
        }
        if (isRawExport) {
          return data[ApiResponseNames.sellHoldLimit];
        } else {
          return data[ApiResponseNames.sellHoldLimit]?.toLocaleString();
        }
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        if (
          hideInactive &&
          (data[ApiResponseNames.useNetTrading] || !data[ApiResponseNames.isHoldActive])
        ) {
          return emptyTableCell;
        } else {
          return data[ApiResponseNames.sellHoldLimit]?.toLocaleString();
        }
      },
      width: limoLimitWidth2,
      id: ApiResponseNames.sellHoldLimit,
      sortType: (a, b) => {
        [a, b] = getPTRValueParent([a, b], hasRiskConfigPrefix, prefixStr);

        const aa = ptraGetColumnValueForSort(a[ApiResponseNames.sellHoldLimit], {
          hideInactive,
          checks: [a[ApiResponseNames.useNetTrading], !a[ApiResponseNames.isHoldActive]],
        });
        const bb = ptraGetColumnValueForSort(b[ApiResponseNames.sellHoldLimit], {
          hideInactive,
          checks: [b[ApiResponseNames.useNetTrading], !b[ApiResponseNames.isHoldActive]],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.sellRejectLimit]: {
      Header: "Sell Kill Limit",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        if (isRawExport) {
          return row[ApiResponseNames.sellRejectLimit];
        } else {
          return row[ApiResponseNames.sellRejectLimit]?.toLocaleString();
        }
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        if (
          hideInactive &&
          (data[ApiResponseNames.useNetTrading] || !data[ApiResponseNames.isRejectActive])
        ) {
          return emptyTableCell;
        } else {
          return data[ApiResponseNames.sellRejectLimit]?.toLocaleString();
        }
      },
      width: limoLimitWidth2,
      id: "sellKillLimit",
      sortType: (a, b) => {
        [a, b] = getPTRValueParent([a, b], hasRiskConfigPrefix, prefixStr);

        const aa = ptraGetColumnValueForSort(a[ApiResponseNames.sellRejectLimit], {
          hideInactive,
          checks: [a[ApiResponseNames.useNetTrading], !a[ApiResponseNames.isRejectActive]],
        });
        const bb = ptraGetColumnValueForSort(b[ApiResponseNames.sellRejectLimit], {
          hideInactive,
          checks: [b[ApiResponseNames.useNetTrading], !b[ApiResponseNames.isRejectActive]],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [aggSellStateStr]: {
      Header: "Agg. Sell State",
      Cell: ({ row }) => {
        const alertActive = row.original.riskConfig[ApiResponseNames.isAlertActive];
        const holdActive = row.original.riskConfig[ApiResponseNames.isHoldActive];
        const rejectActive = row.original.riskConfig[ApiResponseNames.isRejectActive];
        if (
          row.original.riskConfig[ApiResponseNames.useNetTrading] ||
          (!alertActive && !holdActive && !rejectActive)
        ) {
          return emptyTableCell;
        } else {
          const actualPercents = calculateRiskPercent(row.original, LIMIT_TYPE.SELL, false);
          const proformaPercents = calculateRiskPercent(row.original, LIMIT_TYPE.SELL, true);
          return (
            <LimoProgress
              id={`sell${row.original.id}`}
              {...getLimoProgressPropsFromPercents(
                actualPercents,
                proformaPercents,
                alertActive,
                holdActive,
                rejectActive,
                "Sell"
              )}
              hideAlert={false}
            />
          );
        }
      },
      accessor: row => {
        const propsCurrent = getAggProgressAttr(row, LIMIT_TYPE.SELL);
        const proformaProps = getAggProgressAttr(row, LIMIT_TYPE.SELL, true);
        return `${proformaProps.value}% of ${proformaProps.kind
          .substr(0, 1)
          .toUpperCase()}${proformaProps.kind.substr(1)}; ${
          propsCurrent.value
        }% of ${propsCurrent.kind.substr(0, 1).toUpperCase()}${propsCurrent.kind.substr(1)}`;
      },
      sortType: (a, b) => limoAggSort(a.original, b.original, LIMIT_TYPE.SELL),
      width: limoProgressWidth,
      height: 100,
      id: "sellprogressbar",
    },
    [ApiResponseNames.totalNet]: {
      Header: "Total Net (Current)",
      accessor: row => formatPTRTotalColumn(row[ApiResponseNames.totalNet]),
      width: limoLimitWidth,
      id: ApiResponseNames.totalNet,
      sortType: (a, b) => {
        const aa = ptraGetColumnValueForSort(a.original[ApiResponseNames.totalNet], {
          hideInactive,
          checks: [],
        });
        const bb = ptraGetColumnValueForSort(b.original[ApiResponseNames.totalNet], {
          hideInactive,
          checks: [],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.totalNetProforma]: {
      Header: "Total Net (Potential)",
      accessor: row => formatPTRTotalColumn(row[ApiResponseNames.totalNetProforma]),
      width: limoLimitWidth,
      id: ApiResponseNames.totalNetProforma,
      sortType: (a, b) => {
        const aa = ptraGetColumnValueForSort(a.original[ApiResponseNames.totalNetProforma], {
          hideInactive,
          checks: [],
        });
        const bb = ptraGetColumnValueForSort(b.original[ApiResponseNames.totalNetProforma], {
          hideInactive,
          checks: [],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.netAlertLimit]: {
      Header: "Net Alert Limit",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        if (isRawExport) {
          return row[ApiResponseNames.netAlertLimit];
        } else {
          return row[ApiResponseNames.netAlertLimit]?.toLocaleString();
        }
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        if (
          hideInactive &&
          (!data[ApiResponseNames.useNetTrading] || !data[ApiResponseNames.isAlertActive])
        ) {
          return emptyTableCell;
        } else {
          return data[ApiResponseNames.netAlertLimit]?.toLocaleString();
        }
      },
      width: limoLimitWidth,
      id: ApiResponseNames.netAlertLimit,
      sortType: (a, b) => {
        [a, b] = getPTRValueParent([a, b], hasRiskConfigPrefix, prefixStr);

        const aa = ptraGetColumnValueForSort(a[ApiResponseNames.netAlertLimit], {
          hideInactive,
          checks: [!a[ApiResponseNames.useNetTrading], !a[ApiResponseNames.isAlertActive]],
        });
        const bb = ptraGetColumnValueForSort(b[ApiResponseNames.netAlertLimit], {
          hideInactive,
          checks: [!b[ApiResponseNames.useNetTrading], !b[ApiResponseNames.isAlertActive]],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.netHoldLimit]: {
      Header: "Net Hold Limit",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        if (isRawExport) {
          return row[ApiResponseNames.netHoldLimit];
        } else {
          return row[ApiResponseNames.netHoldLimit]?.toLocaleString();
        }
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        if (
          hideInactive &&
          (!data[ApiResponseNames.useNetTrading] || !data[ApiResponseNames.isHoldActive])
        ) {
          return emptyTableCell;
        } else {
          return data[ApiResponseNames.netHoldLimit]?.toLocaleString();
        }
      },
      width: limoLimitWidth2,
      id: ApiResponseNames.netHoldLimit,
      sortType: (a, b) => {
        [a, b] = getPTRValueParent([a, b], hasRiskConfigPrefix, prefixStr);

        const aa = ptraGetColumnValueForSort(a[ApiResponseNames.netHoldLimit], {
          hideInactive,
          checks: [!a[ApiResponseNames.useNetTrading], !a[ApiResponseNames.isHoldActive]],
        });
        const bb = ptraGetColumnValueForSort(b[ApiResponseNames.netHoldLimit], {
          hideInactive,
          checks: [!b[ApiResponseNames.useNetTrading], !b[ApiResponseNames.isHoldActive]],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.netRejectLimit]: {
      Header: "Net Kill Limit",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        if (isRawExport) {
          return row[ApiResponseNames.netRejectLimit];
        } else {
          return row[ApiResponseNames.netRejectLimit]?.toLocaleString();
        }
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        if (
          hideInactive &&
          (!data[ApiResponseNames.useNetTrading] || !data[ApiResponseNames.isRejectActive])
        ) {
          return emptyTableCell;
        } else {
          return data[ApiResponseNames.netRejectLimit]?.toLocaleString();
        }
      },
      width: limoLimitWidth,
      id: "netKillLimit",
      sortType: (a, b) => {
        [a, b] = getPTRValueParent([a, b], hasRiskConfigPrefix, prefixStr);

        const aa = ptraGetColumnValueForSort(a[ApiResponseNames.netRejectLimit], {
          hideInactive,
          checks: [!a[ApiResponseNames.useNetTrading], !a[ApiResponseNames.isRejectActive]],
        });
        const bb = ptraGetColumnValueForSort(b[ApiResponseNames.netRejectLimit], {
          hideInactive,
          checks: [!b[ApiResponseNames.useNetTrading], !b[ApiResponseNames.isRejectActive]],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [aggNetStateStr]: {
      Header: "Agg. Net State",
      Cell: ({ row }) => {
        const alertActive = row.original.riskConfig[ApiResponseNames.isAlertActive];
        const holdActive = row.original.riskConfig[ApiResponseNames.isHoldActive];
        const rejectActive = row.original.riskConfig[ApiResponseNames.isRejectActive];
        if (
          !row.original.riskConfig[ApiResponseNames.useNetTrading] ||
          (!alertActive && !holdActive && !rejectActive)
        ) {
          return emptyTableCell;
        } else {
          const actualPercents = calculateRiskPercent(row.original, LIMIT_TYPE.NET, false);
          const proformaPercents = calculateRiskPercent(row.original, LIMIT_TYPE.NET, true);
          return (
            <LimoProgress
              id={`net${row.original.id}`}
              {...getLimoProgressPropsFromPercents(
                actualPercents,
                proformaPercents,
                alertActive,
                holdActive,
                rejectActive,
                "Net"
              )}
              hideAlert={false}
            />
          );
        }
      },
      accessor: row => {
        const propsCurrent = getAggProgressAttr(row, LIMIT_TYPE.NET);
        const proformaProps = getAggProgressAttr(row, LIMIT_TYPE.NET, true);
        return `${proformaProps.value}% of ${proformaProps.kind
          .substr(0, 1)
          .toUpperCase()}${proformaProps.kind.substr(1)}; ${
          propsCurrent.value
        }% of ${propsCurrent.kind.substr(0, 1).toUpperCase()}${propsCurrent.kind.substr(1)}`;
      },
      sortType: (a, b) => limoAggSort(a.original, b.original, LIMIT_TYPE.NET),
      width: limoProgressWidth,
      id: "netprogressbar",
    },
    [ApiResponseNames.perTradeBuyLimit]: {
      Header: "Per Trade Buy Limit",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        if (isRawExport) {
          return row[ApiResponseNames.perTradeBuyLimit];
        } else {
          return row[ApiResponseNames.perTradeBuyLimit]?.toLocaleString();
        }
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        if (hideInactive && !data[ApiResponseNames.isPerTradeActive]) {
          return emptyTableCell;
        } else {
          return data[ApiResponseNames.perTradeBuyLimit]?.toLocaleString();
        }
      },
      width: limoLimitWidth,
      id: ApiResponseNames.perTradeBuyLimit,
      sortType: (a, b) => {
        [a, b] = getPTRValueParent([a, b], hasRiskConfigPrefix, prefixStr);

        const aa = ptraGetColumnValueForSort(a[ApiResponseNames.perTradeBuyLimit], {
          hideInactive,
          checks: [!a[ApiResponseNames.isPerTradeActive]],
        });
        const bb = ptraGetColumnValueForSort(b[ApiResponseNames.perTradeBuyLimit], {
          hideInactive,
          checks: [!b[ApiResponseNames.isPerTradeActive]],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.perTradeSellLimit]: {
      Header: "Per Trade Sell Limit",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        if (isRawExport) {
          return row[ApiResponseNames.perTradeSellLimit];
        } else {
          return row[ApiResponseNames.perTradeSellLimit]?.toLocaleString();
        }
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        if (hideInactive && !data[ApiResponseNames.isPerTradeActive]) {
          return emptyTableCell;
        } else {
          return data[ApiResponseNames.perTradeSellLimit]?.toLocaleString();
        }
      },
      width: limoLimitWidth,
      id: ApiResponseNames.perTradeSellLimit,
      sortType: (a, b) => {
        [a, b] = getPTRValueParent([a, b], hasRiskConfigPrefix, prefixStr);

        const aa = ptraGetColumnValueForSort(a[ApiResponseNames.perTradeSellLimit], {
          hideInactive,
          checks: [!a[ApiResponseNames.isPerTradeActive]],
        });
        const bb = ptraGetColumnValueForSort(b[ApiResponseNames.perTradeSellLimit], {
          hideInactive,
          checks: [!b[ApiResponseNames.isPerTradeActive]],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.maxTradeLimit]: {
      Header: "Max Trade Limit",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        if (isRawExport) {
          return row[ApiResponseNames.maxTradeLimit];
        } else {
          return row[ApiResponseNames.maxTradeLimit]?.toLocaleString();
        }
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        if (hideInactive && !data[ApiResponseNames.isMaxTradeActive]) {
          return emptyTableCell;
        } else {
          return data[ApiResponseNames.maxTradeLimit]?.toLocaleString();
        }
      },
      width: limoLimitWidth,
      id: ApiResponseNames.maxTradeLimit,
      sortType: (a, b) => {
        [a, b] = getPTRValueParent([a, b], hasRiskConfigPrefix, prefixStr);

        const aa = ptraGetColumnValueForSort(a[ApiResponseNames.maxTradeLimit], {
          hideInactive,
          checks: [!a[ApiResponseNames.isMaxTradeActive]],
        });
        const bb = ptraGetColumnValueForSort(b[ApiResponseNames.maxTradeLimit], {
          hideInactive,
          checks: [!b[ApiResponseNames.isMaxTradeActive]],
        });

        return sortFormattedNumber(aa, bb);
      },
      isNumeric: true,
    },
    [ApiResponseNames.buyDefaultAction]: {
      Header: "Hold Default Action",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        return getHoldDefaultActionLabel(row[ApiResponseNames.buyDefaultAction]);
      },
      width: limoLimitWidth,
      id: ApiResponseNames.buyDefaultAction,
    },
    [ApiResponseNames.perTradeBuyAction]: {
      Header: "Per Trade Default Action",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        return getPerTradeDefaultActionLabel(row[ApiResponseNames.perTradeBuyAction]);
      },
      width: limoLimitWidth,
      id: ApiResponseNames.perTradeBuyAction,
    },
    [ApiResponseNames.dataSources]: {
      Header: "Data Sources",
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        return Array.isArray(row[ApiResponseNames.dataSources])
          ? row[ApiResponseNames.dataSources]
              .map(source => {
                if (isRawExport) {
                  return SelectOptions[FieldNames.dataSources].find(
                    option => option.value === source
                  )?.value;
                } else {
                  return SelectOptions[FieldNames.dataSources].find(
                    option => option.value === source
                  )?.label;
                }
              })
              .join("|")
          : "";
      },
      Cell: ({ row }) => {
        let data = row.original;
        if (hasRiskConfigPrefix) {
          data = row.original[prefixStr];
        }
        if (Array.isArray(data[ApiResponseNames.dataSources])) {
          const sources = data[ApiResponseNames.dataSources]
            .map(source => {
              return SelectOptions[FieldNames.dataSources].find(option => option.value === source)
                ?.label;
            })
            .join(", ");
          return (
            <TooltipTableCell id={`datasource_${row.original.id}`}>{sources}</TooltipTableCell>
          );
        }
      },
      width: limoLimitWidth,
      id: ApiResponseNames.dataSources,
    },
    [ApiResponseNames.trfLimits]: {
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        return row[ApiResponseNames.trfLimits]?.toString();
      },
      Header: "Update TRF Limits",
      id: ApiResponseNames.trfLimits,
    },
    [ApiResponseNames.isMaxTradeActive]: {
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        return getYesOrNo(row[ApiResponseNames.isMaxTradeActive]);
      },
      Header: "Max Trade Active",
      id: `is${ApiResponseNames.isMaxTradeActive}`,
    },
    [ApiResponseNames.isPerTradeActive]: {
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        return getYesOrNo(row[ApiResponseNames.isPerTradeActive]);
      },
      Header: "Per Trade Active",
      id: `is${ApiResponseNames.isPerTradeActive}`,
    },
    [ApiResponseNames.isAlertActive]: {
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        return getYesOrNo(row[ApiResponseNames.isAlertActive]);
      },
      Header: "Alert Active",
      id: `is${ApiResponseNames.isAlertActive}`,
      width: 110,
    },
    [ApiResponseNames.isHoldActive]: {
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        return getYesOrNo(row[ApiResponseNames.isHoldActive]);
      },
      Header: "Hold Active",
      id: `is${ApiResponseNames.isHoldActive}`,
      width: 110,
    },
    [ApiResponseNames.isRejectActive]: {
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        return getYesOrNo(row[ApiResponseNames.isRejectActive]);
      },
      Header: "Kill Active",
      id: "isKillActive",
      width: 110,
    },
    [ApiResponseNames.defaultAction]: {
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        return row[ApiResponseNames.buyDefaultAction];
      },
      Cell: ({ row }) => {
        return getHoldDefaultActionLabel(row.original[ApiResponseNames.buyDefaultAction]);
      },
      Header: "Hold Default Action",
      id: ApiResponseNames.defaultAction,
    },
    [ApiResponseNames.perTradeAction]: {
      accessor: row => {
        if (hasRiskConfigPrefix) {
          row = row[prefixStr];
        }
        return row[ApiResponseNames.perTradeBuyAction];
      },
      Cell: ({ row }) => {
        return getPerTradeDefaultActionLabel(row.original[ApiResponseNames.perTradeBuyAction]);
      },
      Header: "Per Trade Action",
      id: ApiResponseNames.perTradeAction,
    },
    [ApiResponseNames.heldCountPerTrade]: {
      Header: "Per Trade Hold",
      accessor: row => row[ApiResponseNames.heldCountPerTrade],
      Cell: ({ row }) => {
        if (row.original[ApiResponseNames.heldCountPerTrade]) {
          return (
            <Flex alignItems="center">
              <Indicator color="warning" />
              <IndicatorText>{row.original[ApiResponseNames.heldCountPerTrade]}</IndicatorText>
            </Flex>
          );
        } else {
          return "";
        }
      },
      sortType: "basic",
      isNumeric: true,
      width: limoLimitWidth,
      id: "perTradHold",
    },
    [ApiResponseNames.userId]: {
      Header: "Updated By",
      accessor: row => row[ApiResponseNames.userId],
      id: ApiResponseNames.userId,
    },
    [ApiResponseNames.timestamp]: {
      Header: "Date/Time",
      accessor: row => moment(row[ApiResponseNames.timestamp]).format("MM/DD/yyyy HH:mm:ss"),
      id: ApiResponseNames.timestamp,
      sortType: sortDateColumn(ApiResponseNames.timestamp),
    },
    [ApiResponseNames.action]: {
      Header: "Intraday / Next Day",
      accessor: row => {
        switch (row[ApiResponseNames.action]) {
          case PTR_AUDIT_ACTION.PTR_INTRADAY_RISK_CONFIG:
            return "Intraday";
          case PTR_AUDIT_ACTION.PTR_NEXT_DAY_RISK_CONFIG:
            return "Next Day";
          default:
            return "Invalid";
        }
      },
      id: ApiResponseNames.action,
    },
  };
};

const singleOrderTables = () => {
  const tables = {};
  enumKeys(BREACHES).forEach(
    table =>
      (tables[
        `${SINGLE_ORDER_PREFIX}${BREACHES[table]}`
      ] = `${SINGLE_ORDER_PREFIX}${BREACHES[table]}`)
  );

  return tables;
};
export const StandardTables = Object.freeze({
  TR_REPORT: "TRReport",
  TR_SCAN: "TRScan",
  TR_REJECTS: "TRRejects",
  UPLOAD_FILES: "UploadFiles",
  UPLOAD_FILE_ISSUES: "UploadFileIssues",
  UPLOAD_ERRORS: "UploadErrors",
  UPLOAD_SUCCESS: "UploadSuccess",
  CV_SUMMARY: "CVSummary",
  CV_HELD: "ClearingHeld",
  CV_KILL_REJECT: "ClearingKilledRejected",
  ST_MIDDLE: "StatMiddle",
  QUERY_TOOL: "QueryHistory",
  MONTAGE_BID: "MontageBid",
  MONTAGE_ASK: "MontageAsk",
  RISK_UPLOAD_UPLOADS: "RiskUploadUploads",
  RISK_UPLOAD_SUCCESSES: "RiskUploadSuccesses",
  RISK_UPLOAD_REJECTS: "RiskUploadRejects",
  RISK_UPLOAD_FILE_ISSUES: "RiskUploadFileIssues",
  PTR_AUDIT_TRAIL: "SettingsPTRAuditTail",
  PTRA_CONFIG: "SettingsPtraAlertConfig",
  PTRA_AGU_CONFIG: "SettingsPtraAlertConfigAGU",
  PTRA_CREATE_VALIDATION: "SettingsPtraCreateValidation",
  PTRA_AGU_CREATE_VALIDATION: "SettingsPtraCreateValidationAGU",
  PTRA_EDIT_VALIDATION: "SettingsPtraEditValidation",
  PTRA_AGU_EDIT_VALIDATION: "SettingsPtraEditValidationAGU",
  PTRA_EMAIL_MANAGER: "SettingsPtraEmailManager",
  RD_CLEARING: "referenceDataClearing",
  RD_AGU: "referenceDataAGU-QSR",
  RD_CUSIP: "referenceDataCUSIP",
  EQRC_FAT_FINGER: "eqrcFatFinger",
  EQRC_RESTRICTED_STOCK_LIST: "eqrcRestrictedStock",
  EQRC_GROSS_EXPOSURE: "eqrcGrossExposure",
  EQRC_MARKET_IMPACT_CHECK: "eqrcMarketImpact",
  EQRC_ORDER_TYPE: "eqrcOrderType",
  EQRC_ADV_CHECK: "eqrcAvgDailyVolume",
  EQRC_ORDER_RATE_THRESHOLDS: "eqrcOrderRate",
  EQRC_SHORT_SALE: "eqrcShortSale",
  EQRC_ALERT_CONFIG: "eqrcAlertConfig",
  EQRC_MAX_SHARES_PER_ORDER: "eqrcMaxSharesPerOrder",
  EQRC_MAX_NOTIONAL_ORDER: "eqrcMaxNotionalOrder",
  EQRC_SHARES_LOCATED_CHECK: "eqrcSharesLocatedCheck",
  EQRC_BROKER_LIST: "eqrcBrokerList",
  EQRC_SHARES_LOCATED_BROKER_LIST: "eqrcSharesLocatedBrokerList",
  EQRC_MONITORING: "EQRC_MONITORING",
  EQRC_MONITORING_DETAILS: "EQRC_MONITORING_DETAILS",
  ORDER_ENTRY: "ORDER_ENTRY",

  EQRC_FAT_FINGER_RESULTS: "eqrcFatFingerResults",
  EQRC_RESTRICTED_STOCK_LIST_RESULTS: "eqrcRestrictedStockResults",
  EQRC_GROSS_EXPOSURE_RESULTS: "eqrcGrossExposureResults",
  EQRC_MARKET_IMPACT_CHECK_RESULTS: "eqrcMarketImpactResults",
  EQRC_ORDER_TYPE_RESULTS: "eqrcOrderTypeResults",
  EQRC_ADV_CHECK_RESULTS: "eqrcAvgDailyVolumeResults",
  EQRC_ORDER_RATE_THRESHOLDS_RESULTS: "eqrcOrderRateResults",
  EQRC_SHORT_SALE_RESULTS: "eqrcShortSaleResults",
  EQRC_MAX_SHARES_PER_ORDER_RESULTS: "eqrcMaxSharesPerOrderResults",
  EQRC_MAX_NOTIONAL_ORDER_RESULTS: "eqrcMaxNotionalOrderResults",
  EQRC_SHARES_LOCATED_CHECK_RESULTS: "eqrcSharesLocatedCheckResults",
  EQRC_BROKER_LIST_RESULTS: "eqrcBrokerListResults",
  EQRC_SHARES_LOCATED_BROKER_LIST_RESULTS: "eqrcSharesLocatedBrokerListResults",
  // ST_ACCEPT: "Accept",
  KEYCLOAK_EXPORT: "KEYCLOAK_EXPORT",
  ...singleOrderTables(),
  PV_REJECTS: "PV_REJECTS",
  PV_REQUESTS: "PV_REQUESTS",
  PV_SUPERVISOR_MONITOR: "PV_SUPERVISOR_MONITOR",
  PV_SUPERVISOR_MONITOR2: "PV_SUPERVISOR_MONITOR2",
});

const PTR_SUMMARY_COLS = PTR_COLUMNS(true, true, false);
const PTR_SUCCESS_COLS = PTR_COLUMNS(false, false, true);
const PTR_REJECT_COLS = PTR_COLUMNS(false, false, true);
const PTR_AUDIT_COLS = PTR_COLUMNS(true, false, true, "message");

const PTR_TABLE_COLUMNS = {
  [StandardTables.CV_SUMMARY]: [
    PTR_SUMMARY_COLS[ApiResponseNames.clearingFirmMPID],
    PTR_SUMMARY_COLS[ApiResponseNames.correspondentMPID],
    PTR_SUMMARY_COLS[ApiResponseNames.useNetTrading],
    PTR_SUMMARY_COLS[ApiResponseNames.totalBuy],
    PTR_SUMMARY_COLS[ApiResponseNames.totalBuyProforma],
    PTR_SUMMARY_COLS[ApiResponseNames.buyAlertLimit],
    PTR_SUMMARY_COLS[ApiResponseNames.buyHoldLimit],
    PTR_SUMMARY_COLS[ApiResponseNames.buyRejectLimit],
    PTR_SUMMARY_COLS[aggBuyStateStr],
    PTR_SUMMARY_COLS[ApiResponseNames.totalSell],
    PTR_SUMMARY_COLS[ApiResponseNames.totalSellProforma],
    PTR_SUMMARY_COLS[ApiResponseNames.sellAlertLimit],
    PTR_SUMMARY_COLS[ApiResponseNames.sellHoldLimit],
    PTR_SUMMARY_COLS[ApiResponseNames.sellRejectLimit],
    PTR_SUMMARY_COLS[aggSellStateStr],
    PTR_SUMMARY_COLS[ApiResponseNames.totalNet],
    PTR_SUMMARY_COLS[ApiResponseNames.totalNetProforma],
    PTR_SUMMARY_COLS[ApiResponseNames.netAlertLimit],
    PTR_SUMMARY_COLS[ApiResponseNames.netHoldLimit],
    PTR_SUMMARY_COLS[ApiResponseNames.netRejectLimit],
    PTR_SUMMARY_COLS[aggNetStateStr],
    PTR_SUMMARY_COLS[ApiResponseNames.heldCountPerTrade],
    PTR_SUMMARY_COLS[ApiResponseNames.perTradeBuyLimit],
    PTR_SUMMARY_COLS[ApiResponseNames.perTradeSellLimit],
    PTR_SUMMARY_COLS[ApiResponseNames.maxTradeLimit],
    PTR_SUMMARY_COLS[ApiResponseNames.buyDefaultAction],
    PTR_SUMMARY_COLS[ApiResponseNames.perTradeBuyAction],
    PTR_SUMMARY_COLS[ApiResponseNames.dataSources],
  ],
  [StandardTables.PTR_AUDIT_TRAIL]: [
    PTR_AUDIT_COLS[ApiResponseNames.clearingFirmMPID],
    PTR_AUDIT_COLS[ApiResponseNames.correspondentMPID],
    PTR_AUDIT_COLS[ApiResponseNames.useNetTrading],
    PTR_AUDIT_COLS[ApiResponseNames.isAlertActive],
    PTR_AUDIT_COLS[ApiResponseNames.isHoldActive],
    PTR_AUDIT_COLS[ApiResponseNames.isRejectActive],
    PTR_AUDIT_COLS[ApiResponseNames.buyAlertLimit],
    PTR_AUDIT_COLS[ApiResponseNames.buyHoldLimit],
    PTR_AUDIT_COLS[ApiResponseNames.buyRejectLimit],
    PTR_AUDIT_COLS[ApiResponseNames.sellAlertLimit],
    PTR_AUDIT_COLS[ApiResponseNames.sellHoldLimit],
    PTR_AUDIT_COLS[ApiResponseNames.sellRejectLimit],
    PTR_AUDIT_COLS[ApiResponseNames.netAlertLimit],
    PTR_AUDIT_COLS[ApiResponseNames.netHoldLimit],
    PTR_AUDIT_COLS[ApiResponseNames.netRejectLimit],
    PTR_AUDIT_COLS[ApiResponseNames.isPerTradeActive],
    PTR_AUDIT_COLS[ApiResponseNames.perTradeBuyLimit],
    PTR_AUDIT_COLS[ApiResponseNames.perTradeSellLimit],
    PTR_AUDIT_COLS[ApiResponseNames.isMaxTradeActive],
    PTR_AUDIT_COLS[ApiResponseNames.maxTradeLimit],
    PTR_AUDIT_COLS[ApiResponseNames.buyDefaultAction],
    PTR_AUDIT_COLS[ApiResponseNames.perTradeBuyAction],
    PTR_AUDIT_COLS[ApiResponseNames.dataSources],
    PTR_AUDIT_COLS[ApiResponseNames.userId],
    PTR_AUDIT_COLS[ApiResponseNames.timestamp],
    PTR_AUDIT_COLS[ApiResponseNames.action],
  ],
  [StandardTables.RISK_UPLOAD_SUCCESSES]: [
    PTR_SUCCESS_COLS[ApiResponseNames.ackType],
    PTR_SUCCESS_COLS[ApiResponseNames.clearingFirmNum],
    PTR_SUCCESS_COLS[ApiResponseNames.correspondentMPID],
    PTR_SUCCESS_COLS[ApiResponseNames.useNetTrading],
    PTR_SUCCESS_COLS[ApiResponseNames.defaultAction],
    PTR_SUCCESS_COLS[ApiResponseNames.isMaxTradeActive],
    PTR_SUCCESS_COLS[ApiResponseNames.maxTradeLimit],
    PTR_SUCCESS_COLS[ApiResponseNames.isPerTradeActive],
    PTR_SUCCESS_COLS[ApiResponseNames.perTradeBuyLimit],
    PTR_SUCCESS_COLS[ApiResponseNames.perTradeAction],
    PTR_SUCCESS_COLS[ApiResponseNames.perTradeSellLimit],
    PTR_SUCCESS_COLS[ApiResponseNames.isAlertActive],
    PTR_SUCCESS_COLS[ApiResponseNames.buyAlertLimit],
    PTR_SUCCESS_COLS[ApiResponseNames.sellAlertLimit],
    PTR_SUCCESS_COLS[ApiResponseNames.netAlertLimit],
    PTR_SUCCESS_COLS[ApiResponseNames.isHoldActive],
    PTR_SUCCESS_COLS[ApiResponseNames.buyHoldLimit],
    PTR_SUCCESS_COLS[ApiResponseNames.sellHoldLimit],
    PTR_SUCCESS_COLS[ApiResponseNames.netHoldLimit],
    PTR_SUCCESS_COLS[ApiResponseNames.isRejectActive],
    PTR_SUCCESS_COLS[ApiResponseNames.buyRejectLimit],
    PTR_SUCCESS_COLS[ApiResponseNames.sellRejectLimit],
    PTR_SUCCESS_COLS[ApiResponseNames.netRejectLimit],
    PTR_SUCCESS_COLS[ApiResponseNames.dataSources],
  ],
  [StandardTables.RISK_UPLOAD_REJECTS]: [
    PTR_REJECT_COLS[ApiResponseNames.ackType],
    PTR_REJECT_COLS[ApiResponseNames.rejectText],
    PTR_REJECT_COLS[ApiResponseNames.clearingFirmNum],
    PTR_REJECT_COLS[ApiResponseNames.correspondentMPID],
    PTR_REJECT_COLS[ApiResponseNames.useNetTrading],
    PTR_REJECT_COLS[ApiResponseNames.defaultAction],
    PTR_REJECT_COLS[ApiResponseNames.isMaxTradeActive],
    PTR_REJECT_COLS[ApiResponseNames.maxTradeLimit],
    PTR_REJECT_COLS[ApiResponseNames.isPerTradeActive],
    PTR_REJECT_COLS[ApiResponseNames.perTradeBuyLimit],
    PTR_REJECT_COLS[ApiResponseNames.perTradeAction],
    PTR_REJECT_COLS[ApiResponseNames.perTradeSellLimit],
    PTR_REJECT_COLS[ApiResponseNames.isAlertActive],
    PTR_REJECT_COLS[ApiResponseNames.buyAlertLimit],
    PTR_REJECT_COLS[ApiResponseNames.sellAlertLimit],
    PTR_REJECT_COLS[ApiResponseNames.netAlertLimit],
    PTR_REJECT_COLS[ApiResponseNames.isHoldActive],
    PTR_REJECT_COLS[ApiResponseNames.buyHoldLimit],
    PTR_REJECT_COLS[ApiResponseNames.sellHoldLimit],
    PTR_REJECT_COLS[ApiResponseNames.netHoldLimit],
    PTR_REJECT_COLS[ApiResponseNames.isRejectActive],
    PTR_REJECT_COLS[ApiResponseNames.buyRejectLimit],
    PTR_REJECT_COLS[ApiResponseNames.sellRejectLimit],
    PTR_REJECT_COLS[ApiResponseNames.netRejectLimit],
    PTR_REJECT_COLS[ApiResponseNames.dataSources],
  ],
};

const queryToolColumns = [
  ...columnDefinitions().filter(
    col =>
      ![
        "Action Result",
        "Fee",
        "Correspondent",
        "Contra Branch Seq #",
        "Exec Branch Seq #",
        "Late Trade Report Flag ",
        "Settlement",
        "Out Mod2",
        "Time Mod2",
        "Inp Mod2",
        "Out Mod3",
        "Inp Mod3",
        "Out Mod4",
        "Time Mod4",
        "Inp Mod4",
        "Report Time",
        "Reject Reason",
      ].includes(col.Header)
  ),
  // query tool result set sends reportTime instead of actTime
  {
    Header: "Report Time",
    accessor: data => data.reportTime,
    id: "reportTime",
  },
  {
    Header: "Inp Mod",
    accessor: data => data["entryTradeModifiers"],
    id: "entryTradeModifiers",
  },
  {
    Header: "Out Mod",
    accessor: data => data["tradeModifiers"],
    id: "tradeModifiers",
  },
];

export const formatPercentTo = unformattedPercentTo => {
  let percentTo = unformattedPercentTo;

  if (isNaN(percentTo)) {
    return 0;
  }

  if (percentTo === Infinity) {
    percentTo = 100;
  } else if (percentTo === -Infinity) {
    percentTo = 0;
  } else if (percentTo < 1 && percentTo > 0) {
    percentTo = 1;
  }
  return Math.min(100, Math.floor(percentTo));
};

export const calculateRiskPercent = (dto, buySellOrNet, isProforma, hideAlert) => {
  const { alertActive, holdActive, rejectActive } = dto.riskConfig;
  const numActive = (!hideAlert && alertActive) + holdActive + rejectActive;
  let total = dto[`${buySellOrNet.charAt(0)}a`];
  if (isProforma) {
    total = dto[`p${buySellOrNet.charAt(0)}a`];
  }
  const alertLimit = alertActive ? dto.riskConfig[`${buySellOrNet}AlertLimit`] : 0;
  const holdLimit = holdActive ? dto.riskConfig[`${buySellOrNet}HoldLimit`] : alertLimit;
  const killLimit = dto.riskConfig[`${buySellOrNet}RejectLimit`];

  if (alertActive && (total <= alertLimit || (!holdActive && !rejectActive))) {
    const percentToAlert = formatPercentTo((total / alertLimit) * 100);

    return { percentTo: percentToAlert, percent: percentToAlert / numActive };
  } else if (holdActive && (total <= holdLimit || !rejectActive)) {
    const percentToHold = formatPercentTo(((total - alertLimit) / (holdLimit - alertLimit)) * 100);

    return {
      percentTo: percentToHold,
      percent: ((!hideAlert && alertActive) * 100) / numActive + percentToHold / numActive,
    };
  } else if (rejectActive) {
    const percentToKill = formatPercentTo(((total - holdLimit) / (killLimit - holdLimit)) * 100);

    return {
      percentTo: percentToKill,
      percent: (100 * (numActive - 1)) / numActive + percentToKill / numActive,
    };
  }
  return { percent: 0, percentTo: 0 };
};

export const getLimoProgressPropsFromPercents = (
  actualPercents,
  proformaPercents,
  alertActive,
  holdActive,
  rejectActive,
  tooltipType
) => {
  const numActive = alertActive + holdActive + rejectActive;
  let bottomText = "N/A",
    topText = "N/A";

  if (alertActive && actualPercents.percent <= 100 / numActive) {
    bottomText = `${actualPercents.percentTo}% of Alert Limit`;
  } else if (
    holdActive &&
    ((!alertActive && actualPercents.percent <= 100 / numActive) ||
      (alertActive && actualPercents.percent <= 200 / numActive))
  ) {
    bottomText = `${actualPercents.percentTo}% of Hold Limit`;
  } else {
    bottomText = `${actualPercents.percentTo}% of Kill Limit`;
  }

  if (alertActive && proformaPercents.percent <= 100 / numActive) {
    topText = `${proformaPercents.percentTo}% of Alert Limit`;
  } else if (
    holdActive &&
    ((!alertActive && proformaPercents.percent <= 100 / numActive) ||
      (alertActive && proformaPercents.percent <= 200 / numActive))
  ) {
    topText = `${proformaPercents.percentTo}% of Hold Limit`;
  } else {
    topText = `${proformaPercents.percentTo}% of Kill Limit`;
  }

  let percentTop = proformaPercents.percent;

  let percentBottom = actualPercents.percent;

  return {
    alertActive,
    holdActive,
    rejectActive,
    percentTop,
    percentBottom,
    tooltipTop: (
      <>
        <span>Total {tooltipType} (Potential)</span>
        <br />
        <span>{topText}</span>
      </>
    ),
    tooltipBottom: (
      <>
        <span>Total {tooltipType} (Current)</span>
        <br />
        <span>{bottomText}</span>
      </>
    ),
  };
};

const limoAggSort = (a, b, buySellOrNet) => {
  const aPercent = calculateRiskPercent(a, buySellOrNet, true).percent;
  const bPercent = calculateRiskPercent(b, buySellOrNet, true).percent;

  const aAlertActive = a.riskConfig[ApiResponseNames.isAlertActive];
  const aHoldActive = a.riskConfig[ApiResponseNames.isHoldActive];
  const aRejectActive = a.riskConfig[ApiResponseNames.isRejectActive];
  const aUseNetTrading = a.riskConfig[ApiResponseNames.useNetTrading];
  const aUseNetTradingAsBool = buySellOrNet === LIMIT_TYPE.NET ? !aUseNetTrading : aUseNetTrading;
  const aIsEmpty = (!aAlertActive && !aHoldActive && !aRejectActive) || aUseNetTradingAsBool;

  const bAlertActive = b.riskConfig[ApiResponseNames.isAlertActive];
  const bHoldActive = b.riskConfig[ApiResponseNames.isHoldActive];
  const bRejectActive = b.riskConfig[ApiResponseNames.isRejectActive];
  const bUseNetTrading = b.riskConfig[ApiResponseNames.useNetTrading];
  const bUseNetTradingAsBool = buySellOrNet === LIMIT_TYPE.NET ? !bUseNetTrading : bUseNetTrading;

  const bIsEmpty = (!bAlertActive && !bHoldActive && !bRejectActive) || bUseNetTradingAsBool;

  if (aIsEmpty && !bIsEmpty) {
    return -1;
  } else if (aIsEmpty && bIsEmpty) {
    return 0;
  } else if (bIsEmpty && !aIsEmpty) {
    return 1;
  }

  if (aPercent > bPercent) {
    return 1;
  } else if (aPercent < bPercent) {
    return -1;
  }
  return 0;
};
export const StandardTablesColumns = {
  // [StandardTables.PV_SUPERVISOR_MONITOR]: pvSupervisorColumns,
  [StandardTables.PV_SUPERVISOR_MONITOR2]: pvSupervisorColumns2,

  [StandardTables.PV_REJECTS]: pvRejectColumns,
  [StandardTables.PV_REQUESTS]: pvRequestColumns,
  [StandardTables.TR_REPORT]: columnDefinitions(),
  [StandardTables.PV_SUPERVISOR_MONITOR]: _.uniqBy(
    [
      { Header: "Symbol", id: "symbol", accessor: "symbol", width: 100 },
      { Header: "MPID", id: "mpid", accessor: "mpid", width: 100 },
      { Header: "Price", id: "price", accessor: "price", width: 100 },
      { Header: "Quantity", id: "quantity", accessor: "quantity", width: 100 },
      { Header: "Reject Reason", id: "rejectReason", accessor: "rejectReason", width: 200 },
      ...columnDefinitions(),
    ],
    "id"
  ),
  [StandardTables.TR_SCAN]: columnDefinitions(),
  [StandardTables.TR_REJECTS]: columnDefinitions(),
  [StandardTables.UPLOAD_FILES]: [
    { accessor: "fileName", Header: "File Name" },
    {
      id: "date",
      accessor: row =>
        row.uploadStarted
          ? moment(row.uploadStarted, "YYYY-MM-DD hh:mm:ss.SSS").format("YYYY-MM-DD")
          : "",
      Header: "Date",
      sortType: sortDateColumn("uploadStarted"),
    },
    {
      id: "started",
      accessor: row =>
        row.uploadStarted
          ? moment(row.uploadStarted, "YYYY-MM-DD HH:mm:ss.SSS").format("HH:mm:ss.SSS")
          : "",
      Header: "Start Time",
    },
    {
      Header: "Error Count",
      accessor: row => {
        return (
          (row.countRejected === undefined ? 0 : row.countRejected) +
          (row.countFailed === undefined ? 0 : row.countFailed)
        );
      },
      Cell: ({ row }) => (
        <span style={{ color: colors.danger }}>
          {(row.original.countRejected === undefined ? 0 : row.original.countRejected) +
            (row.original.countFailed === undefined ? 0 : row.original.countFailed)}
        </span>
      ),
    },
    {
      accessor: "countCsvRows",
      Header: "Total Records",
      Cell: ({ row }) => {
        return <span style={{ color: colors.darkblue }}>{row.original.countCsvRows}</span>;
      },
    },
    {
      Header: "Progress",
      Cell: ({ row }) => {
        return (
          <Progress
            value={
              [0, NaN].includes(
                row.original.countAccepted + row.original.countRejected + row.original.countFailed
              )
                ? 0
                : ((row.original.countAccepted +
                    row.original.countRejected +
                    row.original.countFailed) /
                    row.original.countCsvRows) *
                  100
            }
            color="success"
          />
        );
      },
    },
  ],
  [StandardTables.UPLOAD_FILE_ISSUES]: [
    { Header: "Type", accessor: "type", id: "type" },
    { Header: "Line #", accessor: "line", id: "line" },
    { Header: "Field Name", accessor: "label", id: "label" },
    { Header: "Field Value", accessor: "value", id: "value" },
    { Header: "Error", accessor: "message", id: "message" },
  ],
  [StandardTables.PTR_AUDIT_TRAIL]: PTR_TABLE_COLUMNS[StandardTables.PTR_AUDIT_TRAIL],
  [StandardTables.PTRA_CONFIG]: RiskAlertColumns,
  [StandardTables.PTRA_CREATE_VALIDATION]: RiskAlertCreateValidationColumns,
  [StandardTables.PTRA_EDIT_VALIDATION]: RiskAlertEditValidationColumns,
  [StandardTables.PTRA_AGU_CONFIG]: AguRiskAlertColumns,
  [StandardTables.PTRA_AGU_CREATE_VALIDATION]: AguRiskAlertCreateValidationColumns,
  [StandardTables.PTRA_AGU_EDIT_VALIDATION]: AguRiskAlertEditValidationColumns,
  [StandardTables.PTRA_EMAIL_MANAGER]: RiskAlertEmailManagerColumns,
  [StandardTables.UPLOAD_ERRORS]: UploadStatusTableColumns(StandardTables.UPLOAD_ERRORS),
  [StandardTables.UPLOAD_SUCCESS]: UploadStatusTableColumns(StandardTables.UPLOAD_SUCCESS),
  [StandardTables.CV_SUMMARY]: PTR_TABLE_COLUMNS[StandardTables.CV_SUMMARY],
  [StandardTables.CV_HELD]: HKTableColumns,
  [StandardTables.CV_KILL_REJECT]: HKTableColumns,
  [StandardTables.ST_MIDDLE]: columnDefinitions(),
  [StandardTables.QUERY_TOOL]: queryToolColumns,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.FAT_FINGER_LIMIT}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.DUPLICATIVE_MSG_RATE}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.ORDER_TYPE}`]]: EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.PORT_MSG_RATE}`]]: EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.RESTRICTED_STOCK}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.SHORT_SALE}`]]: EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.SYMBOL_MSG_RATE}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.MARKET_IMPACT_CHECK}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.MIN_CADV}`]]: EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.SHARES_LOCATED}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.MAX_NOTIONAL_ORDER}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.MAX_SHARES_ORDER}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.MAX_NOTIONAL_ORDER}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.EQRC_ISO_NOT_ALLOWED}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.SHORT_SELL_NOT_ALLOWED}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.MARKET_ORDER_NOT_ALLOWED}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.PRE_MARKET_NOT_ALLOWED}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.POST_MARKET_NOT_ALLOWED}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.SHORT_SELL_EXEMPT_NOT_ALLOWED}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.SINGLE_ORDER_NOTIONAL_EXCEEDED}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.EQRC_MAX_QUANTITY_EXCEEDED}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables[`${SINGLE_ORDER_PREFIX}${BREACHES.REG_SHO_STATE_NOT_AVAILABLE}`]]:
    EqrcDetailColumnsDefinitions,
  [StandardTables.MONTAGE_BID]: [
    { Header: "MMID", accessor: "mpid" },
    { Header: "Bid", accessor: "QP" },
    { Header: "Size", accessor: "QQ" },
  ],
  [StandardTables.MONTAGE_ASK]: [
    { Header: "Size", accessor: "QQ" },
    { Header: "Bid", accessor: "QP" },
    { Header: "MMID", accessor: "mpid" },
  ],
  [StandardTables.RISK_UPLOAD_UPLOADS]: [
    {
      accessor: "file",
      Header: "File",
      id: "file",
    },
    { accessor: "status", Header: "Status", id: "status" },
  ],
  [StandardTables.RISK_UPLOAD_FILE_ISSUES]: [
    { accessor: "line", Header: "Line", id: "line" },
    {
      accessor: "label",
      Header: "Label",
      id: "label",
    },
    {
      accessor: "message",
      Header: "Message",
      id: "message",
      Cell: ({ row }) => {
        return <TooltipTableCell id={`message_${row.id}`}>{row.original.message}</TooltipTableCell>;
      },
    },
    { accessor: "type", Header: "Type", id: "type" },
  ],
  // this value gets duped into the rejects table key below
  [StandardTables.RISK_UPLOAD_SUCCESSES]: PTR_TABLE_COLUMNS[StandardTables.RISK_UPLOAD_SUCCESSES],
  [StandardTables.RISK_UPLOAD_REJECTS]: PTR_TABLE_COLUMNS[StandardTables.RISK_UPLOAD_REJECTS],
  [StandardTables.RD_CUSIP]: [
    {
      accessor: ApiResponseNames.nasdaqSymbol,
      Header: "Nasdaq Symbol",
      id: ApiResponseNames.nasdaqSymbol,
      width: 80,
    },
    {
      accessor: ApiResponseNames.siacSymbol,
      Header: "Trade Scan Symbol",
      id: ApiResponseNames.siacSymbol,
      width: 80,
    },
    {
      accessor: ApiResponseNames.tradeEntrySymbol,
      Header: "Trade Entry Symbol",
      id: ApiResponseNames.tradeEntrySymbol,
      width: 130,
    },
    {
      accessor: ApiResponseNames.CUSIP,
      Header: "CUSIP",
      id: ApiResponseNames.CUSIP,
      width: 110,
    },
    {
      accessor: ApiResponseNames.name,
      Header: "Security Name",
      id: ApiResponseNames.name,
      width: 150,
    },
    {
      accessor: ApiResponseNames.market,
      Header: "Market",
      id: ApiResponseNames.market,
      width: 80,
    },
    {
      accessor: ApiResponseNames.description,
      Header: "Description",
      id: ApiResponseNames.description,
      width: 110,
    },
    {
      Header: "ACT Eligible",
      id: ApiResponseNames.actEligible,
      width: 120,
      accessor: row =>
        ["Yes", "No"].includes(row[ApiResponseNames.actEligible])
          ? row[ApiResponseNames.actEligible] === "Yes"
            ? "Act Eligible"
            : "Not Act Eligible"
          : row[ApiResponseNames.actEligible],
    },
    {
      Header: "Clearing Eligible",
      id: ApiResponseNames.clearingEligible,
      width: 160,
      accessor: row =>
        ["Yes", "No"].includes(row[ApiResponseNames.clearingEligible])
          ? row[ApiResponseNames.clearingEligible] === "Yes"
            ? "Clearing Eligible"
            : "Not Clearing Eligible"
          : row[ApiResponseNames.clearingEligible],
    },
    {
      accessor: row => {
        return row[ApiResponseNames.status] === "LIVE" ? "Production" : "Test";
      },
      Header: "Status",
      id: ApiResponseNames.status,
      width: 80,
    },
  ],
  [StandardTables.RD_AGU]: [
    {
      accessor: row => row[ApiResponseNames.executingBroker],
      Header: "Executing MPID",
      id: ApiResponseNames.executingBrokerMPID,
    },
    {
      accessor: row => row[ApiResponseNames.aguParticipant] || row[ApiResponseNames.qsrParticipant],
      Header: "Participant",
      id: ApiResponseNames.mpid,
    },
    {
      accessor: row =>
        new moment(row[ApiResponseNames.firstValidDay]?.toString()).format("MM/DD/YYYY"),
      Header: "First Valid Date",
      width: 140,
      id: ApiResponseNames.firstValidDay,
      sortType: sortDateColumn(ApiResponseNames.firstValidDay),
    },
    {
      accessor: row =>
        new moment(row[ApiResponseNames.lastValidDay]?.toString()).format("MM/DD/YYYY"),
      Header: "Last Valid Date",
      id: ApiResponseNames.lastValidDay,
      sortType: sortDateColumn(ApiResponseNames.lastValidDay),
    },
  ],
  [StandardTables.RD_CLEARING]: [
    {
      accessor: ApiResponseNames.brokerMPID,
      Header: "Correspondent MPID",
      id: ApiResponseNames.brokerMPID,
      width: 130,
    },
    {
      accessor: ApiResponseNames.clearingFirmMPID,
      Header: "Clearing MPID",
      id: ApiResponseNames.clearingFirmMPID,
      width: 110,
    },
    {
      accessor: ApiResponseNames.clearingFirmNSCCNumber,
      Header: "Clearing Firm NSCC Number",
      id: ApiResponseNames.clearingFirmNSCCNumber,
      width: 140,
    },
    {
      Header: "Can Enter As OF Trades",
      id: ApiResponseNames.canEnterAsOfTrades,
      accessor: row =>
        row[ApiResponseNames.canEnterAsOfTrades] === "1" ? "T+2 Eligible" : "Not T+2 Eligible",
      width: 140,
    },
    {
      Header: "First Valid Date",
      id: ApiResponseNames.firstValidDate,
      accessor: row => new moment(row[ApiResponseNames.firstValidDate]).format("MM/DD/YYYY"),
      width: 120,
      sortType: sortDateColumn(ApiResponseNames.firstValidDate),
    },
    {
      Header: "Default Clearing",
      id: ApiResponseNames.defaultClearing,
      accessor: row => (row[ApiResponseNames.defaultClearing] === true ? "Primary" : "Secondary"),
      width: 150,
    },
    {
      accessor: ApiResponseNames.clearingRelationship,
      Header: "Clearing Relationship",
      id: ApiResponseNames.clearingRelationship,
      width: 120,
    },
    {
      Header: "Act Eligible",
      id: ApiResponseNames.trfeligible,
      accessor: row =>
        row[ApiResponseNames.trfeligible] === true ? "ACT Eligible" : "Not ACT Eligible",
      width: 130,
    },
  ],
  [StandardTables.EQRC_FAT_FINGER]: eqrcFatFingerColumns(false),
  [StandardTables.EQRC_RESTRICTED_STOCK_LIST]: eqrcRestrictedStockColumns(false),
  [StandardTables.EQRC_GROSS_EXPOSURE]: eqrcGrossExposureColumns(false),
  [StandardTables.EQRC_MARKET_IMPACT_CHECK]: eqrcMarketImpactColumns(false),
  [StandardTables.EQRC_ORDER_TYPE]: eqrcOrderTypeColumns(false),
  [StandardTables.EQRC_ADV_CHECK]: eqrcAdvColumns(false),
  [StandardTables.EQRC_ORDER_RATE_THRESHOLDS]: eqrcOrderRateColumns(false),
  [StandardTables.EQRC_SHORT_SALE]: eqrcShortSaleColumns(false),
  [StandardTables.EQRC_ALERT_CONFIG]: eqrcAlertConfigColumns,
  [StandardTables.EQRC_MAX_SHARES_PER_ORDER]: eqrcMaxSharesPerOrderColumns(false),
  [StandardTables.EQRC_MAX_NOTIONAL_ORDER]: eqrcMaxNotionalOrderColumns(false),
  [StandardTables.EQRC_SHARES_LOCATED_CHECK]: eqrcSharesLocatedCheckColumns(false),
  [StandardTables.EQRC_BROKER_LIST]: eqrcSharesLocatedCheckColumns(false),
  [StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST]: eqrcSharesLocatedBrokerListColumns(false),

  [StandardTables.EQRC_FAT_FINGER_RESULTS]: eqrcResultsColumns.eqrcFatFingerColumns,
  [StandardTables.EQRC_RESTRICTED_STOCK_LIST_RESULTS]:
    eqrcResultsColumns.eqrcRestrictedStockColumns,
  [StandardTables.EQRC_GROSS_EXPOSURE_RESULTS]: eqrcResultsColumns.eqrcGrossExposureColumns,
  [StandardTables.EQRC_MARKET_IMPACT_CHECK_RESULTS]: eqrcResultsColumns.eqrcMarketImpactColumns,
  [StandardTables.EQRC_ORDER_TYPE_RESULTS]: eqrcResultsColumns.eqrcOrderTypeColumns,
  [StandardTables.EQRC_ADV_CHECK_RESULTS]: eqrcResultsColumns.eqrcAdvColumns,
  [StandardTables.EQRC_ORDER_RATE_THRESHOLDS_RESULTS]: eqrcResultsColumns.eqrcOrderRateColumns,
  [StandardTables.EQRC_SHORT_SALE_RESULTS]: eqrcResultsColumns.eqrcShortSaleColumns,
  [StandardTables.EQRC_ALERT_CONFIG_RESULTS]: eqrcResultsColumns.eqrcAlertConfigColumns,
  [StandardTables.EQRC_MAX_SHARES_PER_ORDER_RESULTS]:
    eqrcResultsColumns.eqrcMaxSharesPerOrderColumns,
  [StandardTables.EQRC_MAX_NOTIONAL_ORDER_RESULTS]: eqrcResultsColumns.eqrcMaxNotionalOrderColumns,
  [StandardTables.EQRC_SHARES_LOCATED_CHECK_RESULTS]:
    eqrcResultsColumns.eqrcSharesLocatedCheckColumns,
  [StandardTables.EQRC_BROKER_LIST_RESULTS]: eqrcResultsColumns.eqrcSharesLocatedCheckColumns,
  [StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST_RESULTS]:
    eqrcResultsColumns.eqrcSharesLocatedBrokerListColumns,

  [StandardTables.EQRC_MONITORING]: EqrcMonitoringColumns,
  [StandardTables.EQRC_MONITORING_DETAILS]: [],

  [StandardTables.ORDER_ENTRY]: OrderEntryColumns,
  [StandardTables.KEYCLOAK_EXPORT]: KeycloakExportColumns,
};

export const AckTypes = {
  SUCCESS: "Success",
  REJECT: "Reject",
  FAILED: "Failed",
  ERROR: "Error",
};

export const TableButtonAction = {
  ACCEPT: "Accept",
  ALLOW: "Allow",
  BREAK: "Break",
  CANCEL: "Cancel",
  CLOSE: "Close",
  COPY: "Copy",
  DECLINE: "Decline",
  LAYOUT: "Layout",
  INHIBIT: "Inhibit",
  MODIFY: "Modify",
  MATCH: "Match",
  RECAP: "Recap",
  REPAIR: "Repair",
  REVERSE: "Reverse",
  EDIT_LIMITS: "Edit Limits",
  EXPORT: "Export",
  EXPORT_LIMITS: "Export Limits",
  RISK_ALERT_COPY: "RiskAlertCopy",
  RISK_ALERT_DELETE: "RiskAlertDelete",
  RISK_ALERT_EDIT: "RiskAlertEdit",
  RISK_ALERT_NEW: "RiskAlertNew",
  EQRC_AUDIT: "EQRCAudit",
  EQRC_COPY: "EQRCCopy",
  EQRC_DELETE: "EQRCDelete",
  EQRC_EDIT: "EQRCEdit",
  EQRC_NEW: "EQRCNew",
  EQRC_ENABLE: "Enable",
  EQRC_DISABLE: "Disable",
  EQRC_ALERT_NEW: "EQRCAlertNew",
  EQRC_HISTORY: "EQRCHistory",
  EQRC_REFRESH: "EQRCRefresh",
  RISK_ALERT_DISABLE: "Disable",
  RISK_ALERT_ENABLE: "Enable",
  RASH_CANCEL: "RASH_CANCEL",
  RASH_MASS_CANCEL: "RASH_CANCEL",
  KEYCLOAK_REFRESH: "KeycloakRefresh",
  PVR_RESUBMIT: "PVR_Resubmit",
  PVR_CLOSE: "PVR_CLOSE",
  POR: "POR",
};

export const TableButtonLabel = {
  ACCEPT: "Accept",
  ALLOW: "Allow",
  AUDIT: "Audit",
  BREAK: "Break",
  CANCEL: "Cancel",
  CLOSE: "Close",
  COPY: "Copy",
  DECLINE: "Decline",
  DELETE: "Delete",
  EDIT: "Edit",
  HISTORY: "History",
  LAYOUT: "Layout",
  INHIBIT: "Inhibit",
  MATCH: "Match",
  MODIFY: "Modify",
  NEW: "NEW",
  RECAP: "Recap",
  REPAIR: "Repair",
  REVERSE: "Reverse",
  EDIT_LIMITS: "Edit Limits",
  EXPORT: "Export",
  EXPORT_LIMITS: "Export Limits",
  EQRC_NEW: "New Rule",
  EQRC_ALERT_NEW: "New Alert",
  DISABLE: "Disable",
  ENABLE: "Enable",
  RASH_MASS_CANCEL: "Mass Cancel",
  REFRESH: "Refresh",
  RESUBMIT: "Resubmit",
  POR: "POR",
};

export const Status = {
  NO_STATUS: "primary",
  ERROR: "danger",
  SUCCESS: "success",
  WARN: "warning",
};

export const UploadStatus = {
  ACCEPTED: "A",
  DONE: "D",
  DONE_WITH_ERRORS: "E",
  FAILED: "F",
  NEW_RECORD: "+",
  PARSED: "S",
  PROCESSING: "P",
  QUEUED: "Q",
  REJECTED: "R",
  UNKNOWN: "?",
};

export const SettingNames = {
  LIMO_MPID: "limoMPIDs",
  EQRC_MONITORING_FORM: "eqrcMonitoringForm",
};

export const TradeStatus = {
  Accepted: "A",
  Broken: "B",
  Canceled: "C",
  Declined: "D",
  Error: "E",
  ForceMatched: "F",
  OneSidedClear: "G",
  Held: "H",
  Inhibited: "I",
  Killed: "K",
  AutoLockedIn: "L",
  Matched: "M",
  Open: "O",
  PendingRepair: "P",
  LockedIn: "R",
  Tape: "T",
  Unmatched: "U",
  CarriedOver: "V",
  Purged: "X",
  Rejected: "-",
  Default: " ",
};

export const TradeStatusVals = {
  [TradeStatus.Accepted]: { id: 1, value: "A", label: "Accepted" },
  [TradeStatus.Broken]: { id: 2, value: "B", label: "Broken" },
  [TradeStatus.Canceled]: { id: 3, value: "C", label: "Canceled" },
  [TradeStatus.Declined]: { id: 4, value: "D", label: "Declined" },
  [TradeStatus.Error]: { id: 5, value: "E", label: "Error" },
  [TradeStatus.ForceMatched]: { id: 6, value: "F", label: "Force Matched" },
  [TradeStatus.OneSidedClear]: { id: 7, value: "G", label: "One Sided Clear" },
  [TradeStatus.Held]: { id: 8, value: "H", label: "Held" },
  [TradeStatus.Inhibited]: { id: 9, value: "I", label: "Inhibited" },
  [TradeStatus.Killed]: { id: 10, value: "K", label: "Killed" },
  [TradeStatus.AutoLockedIn]: { id: 11, value: "L", label: "Auto Locked-in" },
  [TradeStatus.Matched]: { id: 12, value: "M", label: "Matched" },
  [TradeStatus.Open]: { id: 13, value: "O", label: "Open" },
  [TradeStatus.PendingRepair]: { id: 14, value: "P", label: "Pending Repair" },
  [TradeStatus.LockedIn]: { id: 15, value: "R", label: "Locked-in" },
  [TradeStatus.Tape]: { id: 16, value: "T", label: "Tape" },
  [TradeStatus.Unmatched]: { id: 17, value: "U", label: "Unmatched" },
  [TradeStatus.CarriedOver]: { id: 18, value: "V", label: "Carried Over" },
  [TradeStatus.Purged]: { id: 19, value: "X", label: "Purged" },
  [TradeStatus.Rejected]: { id: 20, value: "-", label: "Rejected" },
  [TradeStatus.Default]: { id: 21, value: " ", label: "Default" },
};

export const buySideValues = ["A", "B", "X", "P", "C", "K"];
export const isExecPartyBuyer = (reportingParty, side) => {
  // reporting party is executing and side is buy
  if (reportingParty === "M" && buySideValues.includes(side)) {
    return true;
  }
  // reporting party is contra and side is sell
  if (reportingParty === "O" && !buySideValues.includes(side)) {
    return true;
  }
  return false;
};

export const TradeSources = {
  1: "TRF Carteret",
  2: "TRF Chicago",
};

export const RiskStatus = {
  ACTIVE: "A",
  INACTIVE: "I",
  TRANSITIONAL: "T",
};

export const SELECT_ALL = "_ALL";

export const FirmType = {
  CLEARING: "Clearing",
  CORRESPONDENT: "Correspondent",
};

export const Messages = {
  ONE_ROW: "This action can only be taken on one row.",
  MULTI_ROW: "This action can be taken on one or multiple rows.",
  MAX_ROWS: max => `This action can be taken on a maximum of ${max} row(s).`,
};

export const ReportingParty = {
  Executing: "M",
  Contra: "O",
};

export const eqrcRuleTypeMap = {
  [StandardTables.EQRC_FAT_FINGER]: { label: "Fat Finger", value: "FAT_FINGER" },
  [StandardTables.EQRC_RESTRICTED_STOCK_LIST]: {
    label: "Restricted Stock",
    value: "RESTRICTED_STOCK",
  },
  [StandardTables.EQRC_GROSS_EXPOSURE]: { label: "Gross Exposure", value: "GROSS_EXPOSURE" },
  [StandardTables.EQRC_MARKET_IMPACT_CHECK]: {
    label: "Market Impact Check",
    value: "MARKET_IMPACT_CHECK",
  },
  [StandardTables.EQRC_ORDER_TYPE]: { label: "Order Type", value: "ORDER_TYPE" },
  [StandardTables.EQRC_ADV_CHECK]: {
    label: "Average Daily Volume",
    value: "AVERAGE_DAILY_VOLUME",
  },
  [StandardTables.EQRC_ORDER_RATE_THRESHOLDS]: {
    label: "Order Rate Threshold",
    value: "ORDER_RATE_THRESHOLDS",
  },
  [StandardTables.EQRC_SHORT_SALE]: { label: "Short Sale", value: "SHORT_SALE" },
  [StandardTables.EQRC_MAX_NOTIONAL_ORDER]: {
    label: "Max Notional Order",
    value: "MAX_NOTIONAL_ORDER",
  },
  [StandardTables.EQRC_MAX_SHARES_PER_ORDER]: {
    label: "Max Shares Per Order",
    value: "MAX_SHARES_PER_ORDER",
  },
  [StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST]: {
    label: "Shares Located Check",
    value: "SHARES_LOCATED",
  },
};

export const eqrcRuleTypeOptions = [
  StandardTables.EQRC_FAT_FINGER,
  StandardTables.EQRC_RESTRICTED_STOCK_LIST,
  StandardTables.EQRC_GROSS_EXPOSURE,
  StandardTables.EQRC_MARKET_IMPACT_CHECK,
  StandardTables.EQRC_ORDER_TYPE,
  StandardTables.EQRC_ADV_CHECK,
  StandardTables.EQRC_ORDER_RATE_THRESHOLDS,
  StandardTables.EQRC_SHORT_SALE,
].map(table => eqrcRuleTypeMap[table]);

export const translateSettlementWithT1RegularFlag = (
  settlement,
  sellerDays,
  isT1RegularSettlement
) => {
  if (isT1RegularSettlement) {
    switch (settlement) {
      case SettlementValues.NextDay:
        return SettlementValues.Regular;
      case SettlementValues.Cash:
      case SettlementValues.Regular:
      case SettlementValues.Other:
      default:
        return settlement;
    }
  } else {
    switch (settlement) {
      case SettlementValues.Other:
        if (parseInt(sellerDays) === 2) {
          return SettlementValues.Regular;
        } else {
          return settlement;
        }
      case SettlementValues.NextDay:
      case SettlementValues.Cash:
      case SettlementValues.Regular:
      default:
        return settlement;
    }
  }
};
export const MAX_RETRY = 3;

export const DEFAULT_CONFIRM_REPORT_QUANTITY_NUMBER = 9999;
